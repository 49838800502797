import React from "react"
import PropTypes from "prop-types"
import { DarkLink } from "./NavLink.styles"

const NavLink = ({ children, to }) => <DarkLink to={to}>{children}</DarkLink>

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default NavLink
