import React from "react"
import PageTransition from "gatsby-plugin-page-transitions"
import {
  Contact,
  Header,
  HeaderTitle,
  HeaderSubtitle,
  Content,
  Left,
  Right,
  Image,
  Intro,
  Info,
  Space,
  SocialLink,
} from "../sections/contact.styles"
import logo from "../assets/dzikistyl-company-logo.png"

import SEO from "../components/seo"
import NavLink from "../components/NavLink/NavLink"

const contact = () => (
  <>
    <SEO
      title="Teatr Dzikistyl Company - kontakt"
      keywords={[`Dzikistyl Company kontakt`, `Teatr gdańsk`]}
      description="Teatr Dzikistyl Company jest otwarty na współpracę. Skontaktuj się z nami: dzikistylcompany@wp.pl, tel: 506 999 585."
    />
    <PageTransition>
      <Contact>
        <Header>
          <HeaderTitle>Kontakt</HeaderTitle>
          <HeaderSubtitle>
            Jesteśmy otwarci na współpracę. Skontaktuj się z nami by dowiedzieć
            się wiecej.
          </HeaderSubtitle>
        </Header>
        <Content>
          <Left>
            <Intro>Email:</Intro>
            <Info>dzikistylcompany@wp.pl</Info>
            <Intro>Telefon:</Intro>
            <Info>506 999 585</Info>
            <Intro>Social media:</Intro>
            <SocialLink
              href="https://pl-pl.facebook.com/DzikiStyl"
              title="Profil na Facebooku"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/dzikistyl_company/"
              title="Profil na Instagramie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </SocialLink>
            <SocialLink
              href="https://www.youtube.com/user/dzikistylcrew"
              title="Kanał na Youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              Youtube
            </SocialLink>
          </Left>
          <Right>
            <Image src={logo} alt="Dzikistyl Company" />
          </Right>
        </Content>
        <NavLink to="/">Home</NavLink>
        <Space />
      </Contact>
    </PageTransition>
  </>
)

export default contact
