import styled from "styled-components"
import { Link } from "gatsby"
import { fonts, colors } from "../../utils/styles"

export const DarkLink = styled(Link)`
  display: block;
  width: 140px;
  height: 38px;
  line-height: 34px;
  text-align: center;
  background: ${colors.mainBlack};
  border: none;
  color: ${colors.mainWhite};
  font-family: ${fonts.fontPrimary};
  font-weight: ${fonts.fontBold};
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: 2.5px solid transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 2.5px solid ${colors.mainBlack};
    background: ${colors.mainWhite};
    color: ${colors.mainBlack};
  }
`
