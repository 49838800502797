import styled from "styled-components"
import { colors, device, fonts, limiter, paddingTop } from "../utils/styles"

export const Contact = styled.section`
  ${limiter};
  ${paddingTop};
  min-height: 100vh;
`

export const Header = styled.header`
  padding-top: 0.4em;
  margin-bottom: 3em;
`

export const HeaderTitle = styled.h1`
  font-weight: ${fonts.fontBold};
  font-size: 1.45rem;
  line-height: 1.9rem;
  margin-bottom: 0.5em;

  @media ${device.tablet} {
    font-size: 2.6rem;
    line-height: 3.15rem;
    margin-bottom: 0.23em;
  }

  @media ${device.laptop} {
    font-size: 3.6rem;
    line-height: 4.1rem;
  }
`

export const HeaderSubtitle = styled.h2`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: ${fonts.fontLight};

  @media ${device.tablet} {
    font-size: 1.3rem;
    line-height: 1.9rem;
  }

  @media ${device.laptop} {
    font-size: 1.6rem;
    line-height: 2.9rem;
  }
`

export const Content = styled.div`
  padding-bottom: 3em;

  @media ${device.tablet} {
    padding-bottom: 4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Left = styled.div``

export const Right = styled.figure`
  display: none;
  padding-left: 3.4em;

  @media ${device.tablet} {
    display: block;
  }

  @media ${device.laptop} {
    margin-right: 5em;
  }
`

export const Image = styled.img`
  @media ${device.laptop} {
    transform: scale(1.25);
  }
`

export const Intro = styled.p`
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-top: 2em;
  margin-bottom: 0.3em;
  font-weight: ${fonts.fontLight};

  @media ${device.tablet} {
    margin-top: 3em;
  }
`

export const Info = styled.p`
  font-size: 1rem;
  line-height: 1.45rem;
  font-weight: ${fonts.fontRegular};

  @media ${device.tablet} {
    font-size: 1.2rem;
    line-height: 1.65rem;
  }
`

export const SocialLink = styled.a`
  font-size: 1rem;
  line-height: 1.65rem;
  font-weight: ${fonts.fontRegular};
  display: block;
  color: ${colors.mainBlack};

  @media ${device.tablet} {
    font-size: 1.2rem;
    line-height: 1.85rem;
  }
`

export const Space = styled.span`
  display: block;
  width: 100%;
  height: 3em;

  @media ${device.tablet} {
    height: 5em;
  }
`
